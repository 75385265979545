#donation-page-container {
  height: 100%;
}

#donation-info {
  font-size: larger;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 12vw;
}

#donation-p {
  padding-left: 12vw;
  padding-right: 12vw;
}
#countries {
  text-align: center;
  margin-top: 70px;
}
.country-btn {
  box-sizing: border-box;
  width: 330px;
  height: 55px;
  margin-bottom: 40px;
  border: none;
  align-content: center;
  line-height: 55px;
  background: linear-gradient(0deg, #FFFBFB, #FFFBFB), #FFFBFB;
  border: 1px solid #FFFBFB;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.payment-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  text-align: center;
  width: 50%;
  height: auto;
}

.country-modal-footer {
  display: block !important;
}

@media (max-width: 720px) {
  #donation-info {
    padding-left: 0;
  }
  .country-btn {
    width: 280px;
    height: 50px;
    margin-bottom: 20px;
  }
}
