@media(max-width: 1024px) {
  #faq-content-container, #faq-links-container {
    width: 600px !important;
  }
}

@media(max-width: 720px) {
  #faq-content-container, #faq-links-container {
    width: 320px !important;
  }
}

.faq-links:hover{
  color: #e9482b;
}

.faq-page-buttons {
  box-sizing: border-box;
  border-radius: 5px;
  padding: 5px 15px;
  line-height: 30px;
}

.faq-page-buttons:hover {
  background-color: #1e365c;
  color: #cccccc;
}