body {
  font-family: Josefin Sans !important;
  font-size: 20px !important;
  background-color: #fad4cd !important;
}

@media(max-width: 720px) {
  body {
    font-size: 16px !important;
    text-align: center !important;
  }

  .font-size-large {
    font-size: 20px !important;
  }
  .font-size-medium {
    font-size: 18px !important;
  }
  .font-size-small, .font-size-x-small{
    font-size: 12px !important;
  }

  h1 {
    font-size: 22px !important;
  }
  h2 {
    font-size: 22px !important;
  }
  h3 {
    font-size: 19px !important;
  }
  h4 {
    font-size: 16px !important;
  }
  .mobile-text-align-justify {
    text-align: justify !important;
  }

  .mobile-text-align-left {
    text-align: left !important;
  }
}

.display-flex {
  display: flex;
  justify-content: space-between;
}

.hover-underline:hover {
  text-decoration: underline;
  color: inherit;
}

.font-size-large {
  font-size: 30px;
}

.font-size-small {
  font-size: 16px;
}

.font-size-x-small {
  font-size: 12px;
}

.underline {
  text-decoration: underline;
}

.light {
  font-weight: 300;
}

.semibold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.italic {
  font-style: italic;
}

.padding-left {
  padding-left: 2vw;
}

.bg-blue {
  background-color: #1e365c;
}

.bg-beach {
  background-color: #fad4cd;
}

.bg-red {
  background-color: #e9482b;
}

.bg-grey {
  background-color: #cccccc;
}

.color-blue {
  color: #1e365c;
}

.color-red {
  color: #e9482b;
}

.color-beach {
  color: #fad4cd;
}

.color-grey {
  color: #cccccc;
}

.color-green {
  color: #28a745;
}

.red-button {
  background-color: #e9482b;
  color: #1e365c;
  border: none;
}

.blue-button {
  background-color: #1e365c;
  color: #cccccc;
  font-weight: bold;
  border: none;
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center !important;
}

.text-align-right {
  text-align: right;
}

.text-align-justify {
  text-align: justify;
}

.Essays1743 {
  font-family: Essays1743;
}

.Essays1743-bold {
  font-family: Essays1743 Bold;
}

.Essays1743-italic {
  font-family: Essays1743 Italic;
}

.Essays1743-bold-italic {
  font-family: Essays1743 Bold Italic;
}