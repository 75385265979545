body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Josefin Sans', 'Dancing Script'
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');

@font-face {
  font-family: 'Essays1743';
  src: local('Essays1743'), url(./fonts/Essays1743.ttf) format('truetype')
}

@font-face {
  font-family: 'Essays1743 Bold';
  src: local('Essays1743'), url(./fonts/Essays1743-Bold.ttf) format('truetype')
}

@font-face {
  font-family: 'Essays1743 Italic';
  src: local('Essays1743'), url(./fonts/Essays1743-Italic.ttf) format('truetype')
}

@font-face {
  font-family: 'Essays1743 Bold Italic';
  src: local('Essays1743'), url(./fonts/Essays1743-BoldItalic.ttf) format('truetype')
}
