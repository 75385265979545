#navbar {
  position: -webkit-sticky;
  position: sticky;
  z-index: 5;
  top: 0;
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
  background: linear-gradient(0deg, #1e365c, #1e365c), #1e365c;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  align-items: center;
  box-sizing: border-box;
  height: 70px;
}

#matches-up-link {
  color: #e9482b;
}

#matches-up-logo-nav {
  height: 60px;
  width: auto;
}

#matches-up-title {
  margin-left: 15px;
  line-height: 70px;
}

#matches-up-link:link {
  text-decoration: none;
}

#nav-right {
  font-size: 22px;
  font-weight: 400;
}

#resources-link, #home-link, #faqs-link {
  color: #e9482b;
}

#login-link, #logout-link, #my-gscfs, #resources-link, #faqs-link {
  color: #e9482b;
  padding-left: 30px;
}

@media(max-width: 720px) {
  #navbar {
    height: 55px;
    padding: 5px 15px;
    justify-items: center;
  }

  #matches-up-logo-nav {
    height: 40px;
    width: auto;
  }

  #nav-right, #matches-up-title {
    display: none;
  }
}