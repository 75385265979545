#resources-page-container {
  box-sizing: border-box;
  width: 950px;
  margin-left: auto;
  margin-right: auto;
  padding: 30px 20px;
}

#resources-page-header {
  padding: 20px 0;
}

.article-card {
  width: 420px;
  padding: 15px;
  margin: 5px;
}

.display-flex-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.article-content {
  font-size: 18px;
}

.cards-body {
  box-sizing: border-box;
  height: 300px;
}

@media(max-width: 1024px) {
  #resources-page-container {
    width: 600px;
    padding: 30px 0;
  }
}

@media(max-width: 720px) {
  #resources-page-container {
    width: 320px;
  }

  #resources-page-header {
    padding: 10px 0;
  }

  #resources-page-desc {
    text-align: justify;
  }

  .article-content {
    font-size: 12px !important;
    padding: 0 !important;
  }

  .cards-body {
    box-sizing: border-box;
    height: 100%;
  }

  .card-imgs {
    height: 200px;
  }
}
