.homepage-container-padding {
  padding: 5vh 7vw;
}

#intro-video-laptop {
  width: 45vw;
  height: 25.3vw;
}

#intro-video {
  width: 100vw;
  height: 56.25vw;
  display: none;
}

#intro-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#matchesup-logo-intro {
  width: 20vw;
  height: auto;
}

#what-is-matches-up-title {
  font-size: 50px;
  line-height: 50px;
}

#ff-carousel-title-1, #ff-carousel-title-2, #ff-carousel-title-3, 
#gsc-carousel-title-1, #gsc-carousel-title-2, #gsc-carousel-title-3, 
#gsc-carousel-title-4, #gsc-carousel-title-5 {
  font-weight: 700;
  padding-top: 20px;
  margin: auto;
  text-shadow: 2px 2px #D9D9D9;;
  text-decoration: underline 5px #E9482B;
}

#step-1-title, #step-3-title {
  font-weight: 700;
  padding-top: 20px;
  margin: auto;
  text-shadow: 2px 2px black;
  text-decoration: underline 5px #E9482B;
}

#step-2-title {
  font-weight: 700;
  padding-top: 20px;
  margin: auto;
  text-shadow: 2px 2px #D9D9D9;
  text-decoration: underline 5px #E9482B;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-22 {
  font-size: 22px;
}

#ff-carousel {
  padding: 20px 40px;
  height: 620px;
}

#gsc-carousel {
  padding: 20px 40px;
  height: 520px;
}
.ff-carousel-content-div, .step-one-content-div {
  width: 290px;
  margin: auto;
}

.ff-carousel-content-buttons, .step-one-content-buttons {
  width: 290px;
  padding: 10px;
  margin: auto;
  background: #D7CCBC;
  border: 1px solid #D7CCBC;
  border-radius: 10px;
}

.steps-icons {
  font-size: 80px;
}

.ff-carousel-left-number-circles, .step-one-left-number-circles {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  padding: 2px;
  background: #E9482B;
  border: 1px solid #E9482B;
  color: #FFFBFB;
  text-align: center;
  position: absolute;
}

.ff-carousel-right-number-circles, .step-one-right-number-circles {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  padding: 2px;
  background: #1E365C;
  border: 1px solid #1E365C;
  color: #FFFBFB;
  text-align: center;
  position: absolute;
  margin-left: 265px;
}

.align-items {
  align-items: center;
}

#celebration-yay {
  font-family: 'Dancing Script', cursive;
  font-weight: 600;
}

.arrow-up {
  width:0;
  height: 0;
	border-left: 130px solid transparent;
	border-right: 130px solid transparent;
  border-bottom: 20px solid #1e365c;
  margin: 0 auto -1px;
  border-radius: 15px;
}

.arrow-down {
  width:0;
  height: 0;
	border-left: 130px solid transparent;
	border-right: 130px solid transparent;
  border-top: 20px solid #1e365c;
  margin: -1px auto 0;
  border-radius: 15px;
}

#ff-icon {
  font-size: 65px;
}

#gsc-icon {
  font-size: 65px;
  margin-bottom: 5px;
}

#step-two-database-div {
  width: 300px;
  padding: 10px;
  background: #D7CCBC;
  border: 1px solid #D7CCBC;
  border-radius: 15px;
  margin: 20px auto 0;
}

#step-two-email-icon {
  font-size: 80px;
}

#step-two-carousel {
  padding: 20px 40px;
  height: 360px;
}

.step-two-content-div {
  width: 290px;
  margin: auto;
}

#step-3-hi-icon {
  font-size: 40px;
}

.step-two-content-buttons {
  width: 290px;
  padding: 10px;
  background: #D7CCBC;
  border: 1px solid #D7CCBC;
  border-radius: 10px;
  margin: 15px auto 0;
}

#step-three-hello-page-button {
  width: 300px;
  padding: 10px;
  margin: 15px auto 0;
  border-radius: 20px;
}

.step-three-content-buttons {
  width: 100%;
  padding: 10px;
  background: #D7CCBC;
  border: 1px solid #D7CCBC;
  border-radius: 10px;
  margin-top: 15px;
}

#for-people {
  margin-top: 10px;
  line-height: inherit;
}

#to-cover-cost {
  box-sizing: content-box;
  padding-bottom: 2%;
}

#donation-info-link{
  text-decoration: underline;
  color: #1e365c;
}

#donation-info-link:hover {
  color: #E9482B;
}

#resources {
  box-sizing: border-box;
  margin: auto;
}

#resources-btn {
  margin-top: 20px;
  width: 220px;
  height: 50px;
  font-size: 22px;
  border: 1px solid #E9482B;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

#links-in-a-few-words {
  line-height: inherit;
}

#single-communities-form {
  display: flex;
  margin-top: 20px;
  align-items: center;
}

#single-communities-input {
  width: 300px;
  height: 55px;
  background: linear-gradient(0deg, #FFFBFB, #FFFBFB), #FFFBFB;
  border: 1px solid #FFFBFB;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 20px;
}

#single-communities-input:focus {
  outline: #fad4cd;
}

#single-communities-form-submit-btn {
  width: 150px;
  height: 50px;
  border: none;
  border-radius: 25px;
  box-sizing: border-box;
  letter-spacing: 2px;
  line-height: 50px;
  margin-left: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}

#single-communities-form-submit-btn:hover {
  background-color: #E9482B;
  color: #1e365c;
}

#send-us-an-email-btn {
  width: 250px;
  height: 60px;
  background: linear-gradient(0deg, #FFFBFB, #FFFBFB), #FFFBFB;
  border: 1px solid #FFFBFB;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 20px;
  margin: 30px auto;
}

#send-us-an-email-btn:focus {
  background-color: #E9482B;
}

/* 1024px */
@media (max-width: 1020px) {
  #intro-flex {
    flex-direction: column;
  }

  #intro-video-laptop {
    display: none;
  }

  #intro-video {
    display: block;
  }

  #matchesup-logo-intro {
    width: 250px !important;
    height: auto;
    display: block;
    margin: auto;
  }
  
  #what-is-matches-up-title {
    display: block;
    width: 100%;
    font-size: 40px;
    line-height: 40px;
  }

  #single-communities {
    text-align: left;
  }
}


/* 720px */
@media (max-width: 720px) {
  .homepage-container-padding-font {
    font-size: 16px;
  }

  #intro-flex {
    margin-bottom: 2%;
  }

  #matches-up-logo-intro {
    width: 50%;
  }

  #what-is-matches-up-title {
    font-size: 25px;
    line-height: 25px;
  }
  
  #finally-mobile {
    display: block;
    font-size: 16px;
    padding-bottom: 15px;
  }
  
  #finally {
    display: none;
  }

  #a-few-words-title {
    font-weight: 700;
    font-size: 22px;
    line-height: 22px;
    text-align: left;
  }

  #ff-carousel{
    height: 580px;
  }

  .ff-carousel-content-div, .step-one-content-div {
    width: 290px;
    float: none;
    margin: auto;
  }

  #ff-icon, #gsc-icon {
    font-size: 50px;
  }

  #step-3-hi-icon {
    font-size: 30px;
  }
  
  .ff-carousel-content-buttons {
    width: 220px;
  }

  .ff-carousel-right-number-circles {
    margin-left: 235px;
  }

  .ff-carousel-left-number-circles {
    margin-left: 34px;
  }

  #ff-carousel-celebration-icon {
    font-size: 40px;
  }

  #step-two-email-icon {
    font-size: 60px;
  }

  #step-two-database-div {
    width: 230px;
    padding: 10px;
    border-radius: 10px;
  }

  .step-two-content-buttons {
    width: 220px;
    padding: 10px;
    margin: none;
  }

  #step-three-hello-page-button {
    width: 220px;
    padding: 10px;
    margin: 10px auto 0;
    border-radius: 10px;
  }

  #step-three-carousel {
    height: 340px;
  }

  .step-three-content-div {
    width: 100%;
  }

  .step-three-content-buttons {
    width: 250px;
    padding: 10px;
    background: #D7CCBC;
    border: 1px solid #D7CCBC;
    border-radius: 10px;
    margin: 10px auto 0;
  }

  #resources-btn {
    width: 120px;
    height: 30px;
    font-size: 14px;
    line-height: 30px;
  }

  #a-few-words-title {
    font-size: 18px;
  }

  #single-communities-input {
    width: 160px;
    height: 30px;
  }
  
  #single-communities-form-submit-btn {
    width: 80px;
    height: 30px;
    line-height: 30px;
    margin-left: 5px;
    font-size: 12px;
  }

  #alert-div {
    text-align: left;
  }

  #have-a-question {
    font-size: 22px;
    margin: 0;
  }

  #send-us-an-email-btn {
    width: 180px;
    height: 30px;
    font-size: 14px;
  }
}